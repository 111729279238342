import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

interface HomeFurnishingProps {
  data: any;
}

const HomeFurnishing: React.FC<HomeFurnishingProps> = ({ data }) => {
  return (
    <>
      <section className={styles.furnishsection}>
        <div className="container-fluid">
          <div className={styles.furnishinner}>
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.furnishheading}>
                  <div className={styles.furnishheadingleft}>
                    <h2>{data?.title}</h2>
                    <p>{data?.subtitle}</p>
                  </div>
                  <Link href="/home-furnishing" className="view-all-btn">
                    View All
                    <Image
                      src="/images/categories/view-all-link-arrow.svg"
                      alt="media"
                      width={17}
                      height={17}
                      quality={75}
                      loading="lazy"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.furnishcontent}>
              <div className="wdRow wdg-3">
                {data?.items?.map((category: any, index: number) => (
                  <div
                    className="wdCol-lg-3 wdCol-md-3 wdCol-sm-6 wdCol-12"
                    key={index}
                  >
                    <Link href={category.link} className={styles.furnishcard}>
                      <div className={styles.furnishimg}>
                        <figure>
                          <Image
                            src={category.imageUrl}
                            alt={`Online Home Furnishing ${category.name}`}
                            width={418}
                            height={363}
                            quality={75}
                            loading="lazy"
                          />
                        </figure>
                      </div>
                      <div className={styles.furnishtext}>
                        <h4> {category.name}</h4>
                        <p>
                          {category.optionsCount} |{' '}
                          <span>{category.startingPrice}</span>
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeFurnishing;
